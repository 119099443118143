@alias
  d:           display
  pos:         position
  bg:          background
  bg-color:    background-color
  bg-image:    background-image
  bg-origin:   background-origin
  bg-position: background-position
  bg-repeat:   background-repeat
  bg-size:     background-size

  fs:          font-size
  fw:          font-weight
  fh:          line-height
  ff:          font-family
  fa:          text-align
  fd:          text-decoration
  ft:          text-transform
  f-dir:       flex-direction
  ta:          text-align
  td:          text-decoration

  lh:          line-height

  h:           height
  max-h:       max-height
  min-h:       min-height
  w:           width
  max-w:       max-width
  min-w:       min-width

  ma:          margin
  mt:          margin-top
  mr:          margin-right
  mb:          margin-bottom
  ml:          margin-left

  pa:          padding
  pt:          padding-top
  pr:          padding-right
  pb:          padding-bottom
  pl:          padding-left

  tr:          transition
  jc:          justify-content
  ai:          align-items

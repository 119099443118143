@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-grid: "\EA01"
$icon-chevron-left: "\EA02"
$icon-chevron-right: "\EA03"
$icon-chevron-up: "\EA04"
$icon-chevron-down: "\EA05"
$icon-eye: "\EA06"
$icon-eye-off: "\EA07"
$icon-close: "\EA08"
$icon-info: "\EA09"
$icon-download: "\EA10"
$icon-refresh: "\EA11"
$icon-edit: "\EA12"
$icon-trash: "\EA13"
$icon-search: "\EA14"
$icon-file: "\EA15"
$icon-plus: "\EA16"
$icon-send: "\EA17"
$icon-cancel: "\EA18"
$icon-save: "\EA19"
$icon-date: "\EA20"
$icon-user: "\EA21"
$icon-filter: "\EA22"
$icon-lock: "\EA23"
$icon-phone: "\EA24"
$icon-phone-incoming: "\EA25"
$icon-menu: "\EA26"
$icon-clear-filter: "\EA27"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-grid:before
	content: $icon-grid
.icon-chevron-left:before
	content: $icon-chevron-left
.icon-chevron-right:before
	content: $icon-chevron-right
.icon-chevron-up:before
	content: $icon-chevron-up
.icon-chevron-down:before
	content: $icon-chevron-down
.icon-eye:before
	content: $icon-eye
.icon-eye-off:before
	content: $icon-eye-off
.icon-close:before
	content: $icon-close
.icon-info:before
	content: $icon-info
.icon-download:before
	content: $icon-download
.icon-refresh:before
	content: $icon-refresh
.icon-edit:before
	content: $icon-edit
.icon-trash:before
	content: $icon-trash
.icon-search:before
	content: $icon-search
.icon-file:before
	content: $icon-file
.icon-plus:before
	content: $icon-plus
.icon-send:before
	content: $icon-send
.icon-cancel:before
	content: $icon-cancel
.icon-save:before
	content: $icon-save
.icon-date:before
	content: $icon-date
.icon-user:before
	content: $icon-user
.icon-filter:before
	content: $icon-filter
.icon-lock:before
	content: $icon-lock
.icon-phone:before
	content: $icon-phone
.icon-phone-incoming:before
	content: $icon-phone-incoming
.icon-menu:before
	content: $icon-menu
.icon-clear-filter:before
	content: $icon-clear-filter
